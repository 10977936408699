var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Ticks", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "With Ticks you can allow the user to select a value inside a range of allowed values. With the property "
        ),
        _c("strong", [_vm._v("step")]),
        _vm._v(" you can change the distance between each Tick"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-slider", {
            attrs: { step: "10" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-slider", {
            attrs: { ticks: "", step: "25" },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-slider", {
            attrs: { ticks: "", step: "0.5", "step-decimals": true },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-slider step=10 v-model="value1"/>\n  <vs-slider ticks step=25 v-model="value2"/>\n  <vs-slider ticks step=0.5 :step-decimals="true" v-model="value3"/>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:60,\n      value2:25,\n      value3: 25.5\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }