var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Range", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "With Range you can allow the user to select a range inside a range of allowed values. You can use the v-model property with "
        ),
        _c("strong", [_vm._v("Array")]),
        _vm._v(" and a minimum and maximum value represented as: "),
        _c("code", [_vm._v("[min, max]")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-slider", {
            attrs: { color: "warning" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-slider", {
            attrs: { color: "danger", ticks: "", step: "10" },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-slider color="warning" v-model="value1"/>\n  <vs-slider color="danger" ticks step=10 v-model="value2"/>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:[60,90],\n      value2:[30,40]\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }