var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Text Fixed", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Determine the text that will be fixed next to the value with the property "
        ),
        _c("code", [_vm._v("text-fixed")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-slider", {
            attrs: { color: "success", "text-fixed": "%" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-slider", {
            attrs: {
              color: "danger",
              "text-fixed": "Min",
              ticks: "",
              step: "25",
            },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-slider", {
            attrs: { color: "dark", "text-fixed": "K", ticks: "", max: "5" },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-slider color="success" text-fixed=% v-model="value1"/>\n  <vs-slider color="danger" text-fixed=Min ticks step=25 v-model="value2"/>\n  <vs-slider color="dark" text-fixed=K ticks max=5 v-model="value3"/>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      value1:6,\n      value2:25,\n      value3:[2,4]\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }