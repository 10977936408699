var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To implement a "),
        _c("strong", [_vm._v("Slider")]),
        _vm._v(" we have the "),
        _c("code", [_vm._v("vs-slider")]),
        _vm._v(" component"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-slider", {
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-slider", {
            attrs: { disabled: "true" },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-slider", {
            attrs: { max: "200" },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-slider v-model="value1" />\n  <vs-slider disabled="true" v-model="value2" />\n  <vs-slider max=200 v-model="value3" />\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      value1: 67,\n      value2: 34,\n      value3: 60\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }