var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the slider's color with the property color. You can also add the main "
        ),
        _c("code", [_vm._v("colors")]),
        _vm._v(" like: "),
        _c("code", [_vm._v("primary")]),
        _vm._v(", "),
        _c("code", [_vm._v("success")]),
        _vm._v(", "),
        _c("code", [_vm._v("danger")]),
        _vm._v(", "),
        _c("code", [_vm._v("warning")]),
        _vm._v(", "),
        _c("code", [_vm._v("dark")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorx,
                expression: "colorx",
              },
            ],
            attrs: { type: "color", name: "" },
            domProps: { value: _vm.colorx },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorx = $event.target.value
              },
            },
          }),
          _c("vs-slider", {
            attrs: { color: _vm.colorx },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <input type="color" v-model="colorx" name="" >\n  <vs-slider :color="colorx" v-model="value1"/>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      colorx:\'#db7d12\',\n      value1:24,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }