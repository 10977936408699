var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Change Method", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can add a function that will be triggered every time the value changes with "
        ),
        _c("code", [_vm._v('@change="MyFunction"')]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "You can change the minimum or maximum number admitted with the properties: "
            ),
            _c("code", [_vm._v("min")]),
            _vm._v(", "),
            _c("code", [_vm._v("max")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-slider", {
            attrs: { min: 50, color: "rgb(45, 208, 129)" },
            on: { change: _vm.cambio },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "p-2 bg-success rounded text-white flex items-center justify-center",
              style: { width: _vm.widthx + "px", height: _vm.heightx + "px" },
            },
            [_vm._v(" " + _vm._s(_vm.value1) + " ")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-slider :min="50" @change="cambio" color="rgb(45, 208, 129)" v-model="value1"/>\n  <div :style="{\'width\':widthx+\'px\',\'height\':heightx+\'px\'}" class="box"> ' +
            _vm._s(_vm.value1) +
            " </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:55,\n      widthx:55,\n      heightx:55\n    }\n  },\n  methods:{\n    cambio(value){\n      this.widthx = value\n      this.heightx = value\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }