var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can add an icon with the "),
        _c("code", [_vm._v("icon")]),
        _vm._v(" property to better representation the sliders to the user"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v("."),
          ]),
          _c("p", [
            _vm._v(
              "FontAwesome and other fonts library are supported. Simply use the icon-pack with fa or fas. You still need to include the Font Awesome icons in your project."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-slider", {
            attrs: { icon: "volume_up", max: "10" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-slider", {
            attrs: { icon: "group_add", ticks: "", step: "25" },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-slider", {
            attrs: { icon: "star", ticks: "", max: "5" },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-slider icon="volume_up" max=10 v-model="value1"/>\n  <vs-slider icon="group_add" ticks step=25 v-model="value2"/>\n  <vs-slider icon="star" ticks max=5 v-model="value3"/>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      value1:6,\n      value2:25,\n      value3:[2,4]\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }